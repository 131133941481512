.scroll-button-control__button {
    background: linear-gradient(#ffd43c, #ffbb01) !important;
    box-shadow: 0 6px 10px #000000c4;
}

.scroll-button-control__container {
    background-color: #062631;
}

.scroll-button-control__fragmet {
    color: #338398;
    font-style: italic;
    @media only screen and (max-width: $break-point--320) {
        font-size: 1.2rem !important;
    }
}

.scroll-button-control__fragmet__current {
    color: #ac3900;
    font-weight: $font-weight-bold;
    text-shadow: 0 1px 0 #812e05;
    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--normal !important;
    }

}

.scroll-button-control__container {
    box-shadow: inset 0px 1px 0px #020c10, inset 0 -1px 0 #13617c;
}
/*****************************
*    Default button styles   *
*****************************/
$button-text-size-large: $text__size--large - 0.25rem;
$button-text-size-normal: $text__size--normal - 0.25rem;
$button-text-size-small: $text__size--small;

// Placeholders
%button {
    position: relative;
    display: block;
    user-select: none;
    border: none;
    color: $button__text--color;
    background-color: transparent;
    width: 100%;
    height: 100%;

    &:before,
    &:after {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        pointer-events: none;
        background: none;
        top: 0;
        left: 0;
        box-sizing: border-box;
        border-radius: inherit;
    }
    &:focus {
        outline: 0;
    }
    &:disabled {
        &:hover {
            cursor: not-allowed;
        }
    }
    &:hover {
        cursor: pointer;
    }
}

// Helper functions
// Get desired color from buttons color map
@function get-color($color, $shade: "base", $map: $button-colors-map) {
    $parent: map-get($map, $color);
    @return map-get($parent, $shade);
}

// Main button decorator
@mixin button_decorator($type) {
    @if ($type == $button--white) {
        background-color: $color--white;
    } @else if ($type != $button--transparent) {
        $border-top-color: get-color($type, border-top);
        $background-color: get-color($type, background);
        $border-bottom-color: get-color($type, border-bottom);
        $box-shadow: get-color($type, box-shadow);
        $text-color: get-color($type, color);
        $text-weight: get-color($type, font-weight);
        $text-shadow: get-color($type, text-shadow);
        $icon-shadow-color: get-color($type, icon-shadow-color);

        background: $background-color;
        box-shadow: $box-shadow;

        &:before {
            border-top: $border-top-color;
        }
        &:after {
            border-bottom: $border-bottom-color;
        }

        > p {
            color: $text-color;
            font-weight: $text-weight;
            text-shadow: $text-shadow;
        }

        > .bc__icon--cmp,
        > .bc__button--icon-cmp {
            color: $text-color;
            font-weight: $text-weight;
            text-shadow: $text-shadow;
        }

        @if ($type == $button--action) {
            > .bc__icon--cmp,
            > .bc__button--icon-cmp {
                &.chip, &.vip{
                    color: $text-color;
                    border-radius: 50%;
                    border: 1px solid currentColor;
                    background-color: currentColor;
                }
            }
        }

        @include touch-hover() {
            $border-top-color-active: get-color($type, border-top-active);
            $background-color-active: get-color($type, background-active);
            $border-bottom-color-active: get-color($type, border-bottom-active);

            background: $background-color-active;
            border-top: none;
            border-bottom: none;
            transition: none;

            &:before {
                background: none;
                border-top: $border-top-color-active;
            }
            &:after {
                background: none;
                border-bottom: $border-bottom-color-active;
            }
        }

        &:disabled {
            $border-top-color-active: get-color($type, border-top-active);
            $background-color-active: get-color($type, background-active);
            $border-bottom-color-active: get-color($type, border-bottom-active);

            background: $background-color-active;
            border-top: none;
            border-bottom: none;
            filter: brightness(0.6) grayscale(0.4);
            transition: none;
            > p {
                text-shadow: none;
            }

            &:before {
                background: none;
                border-top: $border-top-color-active;
            }
            &:after {
                background: none;
                border-bottom: $border-bottom-color-active;
            }
        }
    } @else {
        border: none;
        background-color: transparent;
        &:hover {
            cursor: pointer;
        }
        &:disabled {
            opacity: 0.9;
            filter: brightness(0.6) grayscale(0.4);
        }
    }
}

// Button that contains only text
@mixin button--text($type) {
    @include button_decorator($type);
    > p {
        @extend %text;
        position: relative;
        display: block;
        font-size: $button-text-size-large;
        line-height: 108% !important;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        color: $color__white;
        margin: 0 0.5rem;
        word-break: break-word;
        text-shadow: none;
        @if ($type == $button--white) {
            color: #757777;
            text-shadow: none;
        }
    }

    @if ($type == $button--transparent) {
        > p {
            text-decoration: underline;
            text-decoration-style: solid;
        }

        &:disabled {
            > p {
                opacity: 0.5;
            }
        }
    }

    > .bc__spinner--cmp {
        width: 2em !important;
        font-size: $button-text-size-large !important;
    }
    @media only screen and (max-width: $break-point--768) {
        > p {
            font-size: $button-text-size-normal;
        }

        > .bc__spinner--cmp {
            font-size: $button-text-size-normal !important;
        }
    }
}

// Button that contains only icon
@mixin button--icon($icon, $type, $icon-size: 2.4rem) {
    @include button_decorator($type);
    font-size: inherit;
    @if ($type == $button--transparent) {
        background-color: transparent;
        transition: color 0.3s ease-in-out;
        &:hover {
            color: $color--selected;
        }

        &:disabled {
            opacity: 0.5;
        }
    }
}

// Button that contains text and icon
@mixin button--icon-text($icon, $type) {
    @include button_decorator($type);
    position: relative;
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    > p {
        @extend %text;
        position: relative;
        display: block;
        font-size: $button-text-size-large;
        line-height: 108% !important;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        color: $color__white;
        text-shadow: none;
        @if ($type == $button--white) {
            color: #757777;
            text-shadow: none;
        }
    }

    @if ($type == $button--transparent) {
        &:disabled {

            > p {
                opacity: 0.5;
            }
        }
    }

    > .bc__spinner--cmp {
        width: 2em !important;
        font-size: $button-text-size-large !important;
    }

    @media only screen and (max-width: $break-point--768) {
        > p {
            font-size: $button-text-size-normal;
        }
        > .bc__spinner--cmp {
            font-size: $button-text-size-normal !important;
        }
    }
    @media only screen and (max-width: $break-point--380) {
        padding: 0 0.25rem;
        > p {
            font-size: $button-text-size-small;
        }

        > .bc__spinner--cmp {
            font-size: $button-text-size-small !important;
        }
    }
}

// Button with no text only image from url
@mixin button--image($type) {
    @include button_decorator($type);
    display: flex;
    justify-content: center;
    align-items: center;
    > img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 3rem;
    }
}

// Button that contains text and image
@mixin button--image-text($type) {
    @include button_decorator($type);
    padding: 0 1rem;
    display: flex;
    justify-content: center;
    align-items: center;

    > img {
        width: 2.5rem;
        position: relative;
        margin-right: 0.75rem;
    }
    > p {
        @extend %text;
        position: relative;
        display: block;
        font-size: $button-text-size-large;
        line-height: 108% !important;
        font-weight: 600;
        text-align: center;
        text-transform: uppercase;
        color: $color__white;
        margin: 0;
        text-shadow: none;
        @if ($type == $button--white) {
            color: #757777;
            text-shadow: none;
        }
    }
    @media only screen and (max-width: $break-point--768) {
        > p {
            font-size: $button-text-size-normal;
        }
    }
}

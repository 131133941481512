.room__wrapper {
    border-radius: $border--radius;

    &.custom {
        background-image: url(imageUrl('lobby/customRoom.png'));
        background-size: cover;
        background-position: 50%;
        background-repeat: no-repeat;
        border-top: .2rem solid #1bfafe;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .box__container--room-icon {
            display: none !important;
        }

        .nav--room--box__wrapper--texts {
            width: 100% !important;
            display: flex;
            flex-direction: column;
            align-items: center;
            font-weight: $font-weight-extra-bold;
            font-size: 5rem !important;
            text-shadow: 0px 2px 0px #00d8ff;
            filter: drop-shadow(0px 5px 7px #004eed);
            margin-left: 0 !important;
            margin-top: 0 !important;

            > span {
                text-transform: lowercase;
                font-style: italic;
            }

            @media screen and (max-width: $break-point--680) {
                font-size: 4rem !important;
            }

            @media screen and (max-width: $break-point--480) {
                font-size: 3rem !important;
            }

            @media screen and (max-width: $break-point--380) {
                font-size: 2.5rem !important;
            }
        }

        .nav--room--box__wrapper--info {
            font-weight: $font-weight-bold;
            display: flex !important;
            justify-content: center;
            font-size: $text__size--large;
        }

        .tile__text--name {
            line-height: initial !important;
        }
    }
}

.nav--box__image--wrapper {
    background-repeat: no-repeat;
    background-position: center;
    background-size: 70%;
    height: 200% !important;
    @media screen and (max-width: $break-point--768) {
        height: 100% !important;
    }
    &.custom {
        max-height: initial;
        max-width: initial;
        top: 50% !important;
        left: 50% !important;
        width: 200% !important;
        position: absolute !important;
        display: block;
    }
}
$leader-last-seen-color: #a70000;

.club--info__label-last-seen {
    color: $leader-last-seen-color;
}

.club--info__button {
    &.logout {
        font-size: 2rem;
    }
}
$knockout-name-font-size: 6rem;
$knockout-name-font-size-mobile: 10vw;

.knockout__header--text {
    font-size: $knockout-name-font-size  !important;
    text-shadow: 0 3px 0 #b500ff, 0 5px 0 #9904bb, 0px 7px 16px #820a83;

    @media screen and (max-width: $break-point--568) {
        font-size: $knockout-name-font-size-mobile  !important;
    }
}

.knockout__container--custom-bet {
    border-top: 1px solid #91348f;
    border-bottom: 1px solid #d27acf;
    margin-top: auto;
    width: 40rem;
    max-width: 95%;

    @media only screen and (max-height: $break-point--680 ) {
        margin-top: auto;
    }

    @media screen and (max-width: $break-point--568) {
        width: 100%;
    }
}

.knockout__tile--wrapper {
    border-top: 0.2rem solid #f7bdf9;
    background: linear-gradient(#e776e9, #9b459c);
    border-radius: $container-border--radius;
    justify-content: flex-end;
}

.knockout__wrapper {
    height: 60%;

    @media only screen and (max-width: $break-point--980 ) {
        height: 65%;
    }
    @media only screen and (max-width: $break-point--768 ) {
        height: 60%;
    }
    @media only screen and (max-width: $break-point--680 ) {
        height: 55%;
    }
    @media only screen and (max-width: $break-point--480 ) {
        height: 60%;
    }
    @media only screen and (max-width: $break-point--440 ) {
        height: 68%;
    }
    @media only screen and (max-width: $break-point--380 ) {
        height: 70%;
    }
    @media only screen and (max-width: $break-point--320 ) {
        height: 73%;
    }
}

.knockout__tournament--footer-bar {
    position: relative !important;
    background-color: transparent;

    &.new-design {
        height: 3rem;
    }
}

.knockout__win--games {
    color: #f2b1ef;
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--small;
    }
    >span {
        color: #ffc1fc;
        font-weight: $font-weight-bold;
    }
}

.knockout__container--wrapper-play.bottom {
    display: none;
}

.knockout__tournament-tile__footer-text {
    position: static;
    color: #f2b1ef;
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--small;
    }
}

.knockout__tournament--tile-info--text {
    text-shadow: 0 1px 0 #74196f;
    font-weight: $font-weight-bold;
    font-size: $text__size--x-large;

    @media screen and (max-width: $break-point--768), screen and (max-height: $break-point--568) {
        font-size: $text__size--large;
    }

    >span {
        color: $color__white;
    }
}

.knockout__tournament--tile-info--icon {
    display: none !important;

    &.new-design {
        display: inline-block !important;
        color: #7e1979;
    }
}

.knockout__bottom-bar--new-design {
    display: block;
}

.knockout__container--wrapper-play {
    .button {
        height: 5rem;
        width: 40rem;
        max-width: 95%;

        @media screen and (max-width: $break-point--768) {
            height: 4rem;
        }

        @media screen and (max-width: $break-point--568) {
            width: 100%;
        }
    }
}

.knockout__button---disabled {
    >img {
        width: 3rem;
    }
}

.knockout__button-play {
    >p {
        font-size: $text__size--large;
    }
}

.knockout__restrictions {
    .restriction-level--host {
        @extend %knockout-tile-info-container;
        font-weight: $font-weight-bold;
        position: absolute;
        top: 1.5rem;
        left: 1.5rem;
    }
}

.knockout--text-rows__wrapper {
    align-items: initial;
    margin-top: auto;
}

.knockout__old-design {
    display: none !important;
}

.knockout__completed--text {
    color: #6b1b6b;
    text-transform: uppercase;
    font-size: $knockout-name-font-size * 1.1 !important;
    text-shadow: 0 -2px 0 #fe6aff, 0 3px 0 #9bcc00, 0 5px 2px black;

    @media screen and (max-width: $break-point--568) {
        font-size: $knockout-name-font-size-mobile * 1.1 !important;
    }
}

.knockout__completed--prize--text-bclass {
    color: $color__chips;
    font-size: $knockout-name-font-size * 0.5 !important;
    text-transform: uppercase;

    @media screen and (max-width: $break-point--568) {
        font-size: $knockout-name-font-size-mobile * 0.5 !important;
    }
}

.knockout__completed--prize--value-bclass {
    font-size: $knockout-name-font-size * 0.7 !important;
    text-shadow: 0 -2px 0 #fed702, 0 1px 0 #9f4301, 0 2px 2px #531049;

    @media screen and (max-width: $break-point--568) {
        font-size: $knockout-name-font-size-mobile * 0.7 !important;
    }
}

.knockout__completed--prize--value-iconClass {
    font-size: $knockout-name-font-size * 0.6 !important;

    @media screen and (max-width: $break-point--568) {
        font-size: $knockout-name-font-size-mobile * 0.6 !important;
    }
}

.knockout__tile--container {
    height: 70%;
    @media only screen and (max-width: $break-point--980 ) {
        height: 66%;
    }
    @media only screen and (max-width: $break-point--768 ) {
        height: 70%;
    }
    @media only screen and (max-width: $break-point--680 ) {
        height: 60%;
    }
    @media only screen and (max-width: $break-point--480 ) {
        height: 63%;
    }

    @media only screen and (max-width: $break-point--380 ) {
        height: 61%;
    }
    @media only screen and (max-width: $break-point--320 ) {
        height: 59%;
    }
}
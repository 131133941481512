.profile-large-button__wrapper {
    background-color: rgb(28 96 111);
    border: none !important;
    margin: 0.5rem 0 !important;
}

.large-button__button {
    flex: .5 !important;
}

.large-button__image-container {
    flex: .3 !important;
}
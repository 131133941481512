.menu--dropdown--item--wrapper-host {
    &:hover {
        background-color: lighten($color__white, 20%);
        border-radius: $radius--default;
        p {
            color: $color__black !important;
        }

        .bc__button--icon-cmp {
            fill: $color__black !important;
        }
    }
}
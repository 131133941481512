.promo-tile__buy-button-bclass {
    box-shadow: 0px 10px 15px #994100;

    > p {
        text-shadow: none !important;
        color: #67370f !important;
    }
}

.promo-tile__star-class {
    &:after {
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background: initial;
        background-color: white;
    }
}

.promo-tile__blink {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 70%;
    height: 80%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.promo-tile__wrapper {
    box-shadow: inset 0 3px 3px #ffffff59;
    border-radius: $border--radius;
    background-color: #edc500;
    background-image: url(imageUrl("promo-tile/promo-default.png"));
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.creator-code__container {
    background-image: url(imageUrl("shop/affiliate-code_background.png"));
    @include coloredContainer($container-dark-background-color,
    $container-dark-upper-border-color,
    $container-dark-inner-shadow-color,
    $color__black);
}

.creator-code__info {
    color: #55bcc2;
}

.creator-code__time-left,
.creator-code__timer {
    color: #ffa400;
}

.creator-code__container--form__button__timer {
    color: #293d00 !important;
}
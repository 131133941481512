.happy-hour-container {
    border-radius: $container-border--radius;
    box-shadow: 0 6px 10px 0 #0000009e;
}

.happy-hour-timer {
    > span {
        color: #9ef000;
    }
}

.happy-hour-flag__container {
    background: linear-gradient(0deg, #070707 0%, #44484c 100%) !important;
    box-shadow: 0 2px 3px #7c6e12 !important;
}
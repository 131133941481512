$chat__bg-color: #efefef;

.pf-tab__container--conversations {
    background-image: none;
    background-color: $chat__bg-color;
}

.pf-tab__search-bar__input {
    background-color: $color--white;
    color: #969696 !important;
}
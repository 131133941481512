$button-border-radius: 10rem;

.ranked-tile-component {
    border-radius: $container-border--radius;
    background: linear-gradient(90deg, #1b4c5a, #367c90 50%, #1b4c5a);
    border-top: 2px solid #44b2bf;
}

.ranked-room-tile__entry--currency-class {
    text-shadow: 0 1px 0 #2b0071, 0 3px 0 #1e7d98, 0 5px 5px #000;
}

.ranked-room-tile__play-bclass {
    border-radius: $button-border-radius;
}

.ranked-room-tile__play-btn--wrapper {
    background-color: #00000021;
    box-shadow: inset 0px 2px 2px 0px $black__transparent--06,
    0px 1px 0 #44b2bf;
    border-radius: $button-border-radius;
}

.ranked-room-tile__inner-glow {
    border-left: 1px solid #2d8398;
    border-right: 1px solid #2d8398;
    box-shadow: inset 0 -1px 25px -5px #1f5b6b;
}
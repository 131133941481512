$color-last-seen: #a70000;

.clubs--list--item__label-last-seen {
    color: $color-last-seen !important;
}

.clubs--list--item__icon-last-seen {
    color: $color-last-seen !important;
}

.clubs--list__separator {
    display: none !important;
}

.clubs--list--item__container {
    @include coloredContainer($container-dark-inner-shadow-color,
        $container-dark-upper-border-color,
        $container-dark-background-color,
        $color__black);
    box-shadow: inset 0 1px 0 0 $container-dark-upper-border-color, inset 0 -2px 3px -1px $container-dark-background-color;

    &.highlighted {
        @include coloredContainer($container-dark-background-color,
            $container-dark-upper-border-color,
            $container-dark-inner-shadow-color,
            $color__black);
        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            opacity: .05;
            background-image: var(--background__pattern);
            pointer-events: none;
        }

        .clubs--list--item__label-last-seen {
            opacity: 0;
        }
    }
}

.clubs--list--item__container--upper-info {
    background: $black__transparent--03;
    border-radius: 0.8rem;
    padding: 1rem;
}

.clubs--list--item__container--buttons {
    justify-content: space-evenly;
}
$background-color: #267485;
$background-inner-shadow: #3096a4;
$container-bonus-upper-border-color: #21cae0;

.popup-container__wrapper--content {
    border: none !important;
    background: none;
    @include popupContainer($container-bonus-upper-border-color,
        $background-inner-shadow, $background-color);
}

.popup__outlet--wrapper-title {
    background-color: transparent !important;

    &:before {
        display: none;
    }

    .popup-title-bar__container--close {
        display: none !important;
    }
}
.shop-promo__tips {
    display: none;
  }

  .promo-code__container--coins {
    display: none !important;
  }

  .shop-promo__tips-redesign {
    display: flex !important;
  }

  .shop-promo__button--benefits {
    width: fit-content;
  }

  .shop-promo__button--benefits-bclass {
    border-radius: 2rem;
    padding: 0 1rem 0 1.5rem;
  }

  .shop-promo__card--wrapper {
    width: 100%;
    .shop-promo__card {
      margin: 3rem 6rem !important;
      width: calc(100% - 12rem) !important;
      @media only screen and ( max-width: $break-point--568) {
        margin: 3rem 3rem !important;
        width: calc(100% - 6rem) !important;
      }
      @media only screen and ( max-width: $break-point--440) {
        margin: 3rem 1rem !important;
        width: calc(100% - 2rem) !important;
        }
      }
  }

  .shop-promo__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding-bottom: 4rem;
    min-height: 100%;
  }

  .shop-promo__text--benefits__container {
    max-width: 50rem;
  }
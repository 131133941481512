$rankings-tile-border-top-color: #ffb7b5;
$rankings-tile-border-bottom-color: #651946;

$color-top-label-title-text-shadow: #7c3802;
$color-top-label-first: #e67b84;
$color-top-label-second: #c83554;

.rankings-tile__wrapper {
    transition: transform 0.6s ease-in-out, border-bottom-color 0.6s !important;
    transform-style: preserve-3d;
}

.rankings-tile__initial,
.rankings-tile__leaders {
    box-shadow: inset 0px 3px 3px rgb(255 255 255 / 35%);
    border-bottom: 0.2rem solid $rankings-tile-border-bottom-color;
}

.rankings-tile__initial,
.rankings-tile__leaders {
    background: linear-gradient(#ff908d, #ff5b5c);
}

.rankings-tile__image--icon {
    left: 50%;
    transform: translateX(-50%);
}

.rankings-tile__wrapper--texts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-weight: $font-weight-extra-bold !important;
    font-size: 2.6rem;
    text-shadow: 0 1px 0px #ddbe97, 0 2.5px 0px #ff5b5c, 0 9px 12px #ce2b01;
    text-transform: uppercase;

    > span {
        text-transform: lowercase;
    }

    @media screen and (max-width: $break-point--568) {
        font-size: $text__size--large;
    }
}

.rankings-tile__leaders {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.rankings-tile__leaders--top {
    background: linear-gradient($color-top-label-first, $color-top-label-second);
    padding: .25rem 1.5rem;
    position: absolute;
    box-sizing: content-box;
    top: 0.25rem;
    left: 50%;
    transform: translateX(-50%);
    max-height: 3rem;
    border-radius: $container-border--radius;
}

.rankings-tile__leaders--top-img {
    width: 100%;
    height: 100%;
}

.rankings-tile__leaderboards--name {
    font-size: $text__size--normal;
    text-align: center;
    color: $color__white;
    font-weight: $font-weight-bold;
    text-shadow: 0 2px 5px $color-top-label-title-text-shadow;
    white-space: nowrap;

    @media screen and (max-width: $break-point--360) {
        font-size: $text__size--small;
    }
}

.rankings-tile-bclass {
    height: 9rem !important;
    width: 6rem !important;

    @media screen and (max-width: $break-point--480) {
        height: 8rem !important;
        width: 5rem !important;
    }
}

.rankings-tile__professionals {
    font-size: 0.8rem;
}
// Images
$background__pattern: url( imageUrl( 'backgrounds/bg.png?forceInline' ) );
$game__logo: imageUrl('game-logo/logo.svg');
$game__host: imageUrl('game-host/host.png');

// Global
$transition--default: .2s ease-in-out;
$radius--default: 0.8rem;
$border--radius: $radius--default;
$container-border--radius: 1.5rem;
$before: 'before';
$after: 'after';

// Icons
$icon__size--default: 2.4rem;
$icon__args: $icon__size--default, $secondary, $before;

$text--shadow: 0 0 .5rem $black__transparent--05;

$container-bonus-background-color: #072f3d;
$color-check-icon: #abdc28;
$color-current-reward: #00d3ff;

.daily-reward--checked-image--wrapper {
    background: $container-bonus-background-color;
    border-radius: 50%;
}

.daily-reward--title {
    color: $color__black;
}

.daily-reward {
    border-radius: $border--radius;
    background: linear-gradient(0deg, #dcdbd8 0%, #f8f8f6 100%);
    transform: translateZ(0);

    &.current {
        box-shadow: 0 0 6px 3px $color-current-reward;
        border: none !important;
    }

    &.registerAdvent {
        @include coloredContainer($container-dark-background-color,
            $container-dark-upper-border-color,
            $container-dark-inner-shadow-color,
            $color__black);

        background: $container-dark-background-color;

        .daily-reward--title {
            color: white;
        }

        &.current {
            background-color: #591ab7!important;
            box-shadow: 0 0 12px 4px $color--selected, 0 0 0px 2px $color--selected, inset 0 1px 0 0 #c869ff, inset 0 1px 7px 1px #c869ff;

            &.highlighted {
                box-shadow: 0 0 12px 4px $color--selected, 0 0 0px 2px $color--selected, inset 0 1px 0 0 #c869ff, inset 0 1px 7px 1px #c869ff;
            }
        }

        &.checked {
            opacity: 0.4;
            filter: brightness(0.5);
        }

        &.highlighted {
            background-color: #591ab7!important;
            box-shadow: inset 0 1px 0 0 #c869ff, inset 0 1px 7px 1px #c869ff;
            border-top: none;
        }
    }
}

.daily-reward--image-wrapper {
    @media only screen and (max-width: $break-point--320) {
        width: 100% !important;
    }
}

.daily-reward-container__reward--value {
    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--small !important;
    }
}

.checked-image {
    &.not-round {
        display: none !important;
    }
    position: absolute;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 70% !important;
    height: 70% !important;
    display: initial !important;
    // could not assign gradient to color or fill prop
    //color: linear-gradient(0deg, #abdc28 0%, #b6e026 100%);
    color: $color-check-icon;
    z-index: 2;
}

.daily-reward-container__reward {
    @include coloredContainer($color-day-reward-container-first-gradient-color,
        transparent, transparent,
        $color-day-reward-container-box-shadow-color,
        $color-day-reward-container-second-gradient-color);
    border-radius: 2rem;
    padding: 0.3rem 0.5rem;
    color: $color-day-reward-text;
}

.daily-reward--checked-image--wrapper {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 2.2rem !important;
    height: 2.2rem !important;
    transform: translate(-5%, -5%) !important;
}

.bdropdown--header {
    border: none;
    background: none;
    box-shadow: none;
}

.bdropdown--body {
    background-color: $color__white;
    border: none !important;
    p {
        color: #19535f !important;
        text-decoration: none !important;
    }
    > *:hover {
        background-color: lighten(#19535f, 65%);
        border-radius: $radius--default;
    }
}



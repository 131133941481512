$color-benefits-label-second: #916100;
$color-benefits-label-first: #ffcb64;
$color-benefit-highlighted-text: #c4932f;
$color-benefit-title-text-shadow: #7c3802;

.shop-text-list__wrapper {
    max-width: inherit;
}

.shop-text-list__text--benefits {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    text-shadow: 0 2px 5px $color-benefit-title-text-shadow;
    font-size: 3rem;

    @media only screen and (max-width: $break-point--768) {
        font-size: $text__size--large;
    }

    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--normal;
    }

    @media only screen and (max-width: $break-point--320) {
        font-size: $text__size--small;
    }

    >span {
        font-weight: $font-weight-bold !important;
    }
}

.shop-text-list__container--benefits-list-item {
    margin: 1.5rem 0 !important;
}

.shop-text-list__container--benefits-label {
    border-radius: 1rem 1rem 0 0;
    height: 9rem !important;
    background: linear-gradient($color-benefits-label-first, $color-benefits-label-second) !important;
}

.shop-text-list__container--benefits-list {
    background-color: transparent !important;
}

.shop-text-list__container--item-text-bullet {
    background-color: transparent !important;
    width: 1.5rem !important;
    height: 1.5rem !important;
    margin-right: 0.5rem !important;
    margin-top: 0.3rem !important;
}

.shop-text-list__text--benefits-list-item-text {
    color: $color__black !important;

    >span {
        color: $color-benefit-highlighted-text !important;
    }
}

.shop-text-list__icon--item-text-bullet {
    display: initial !important;
    color: $color-benefit-highlighted-text !important;
}
.ranked-games-leaderboard-stats {
    background: #43809a;
    border-top: 2px solid #54a0b3;
}

.ranked-games-leaderboard__title {
    color: #d6fff8;
    text-shadow: 0 -1px 0 #fafeff, 0 1px 0px #6bb6a5, 0 2px 0px #123a47;
}

.ranked-games-leaderboard-stats__info-wrapper {
    background: linear-gradient(90deg, #427d97 0%, #35657c 20%, #35657c 80%, #427d97 100%);
}

.shop-vip__wrapper {
    @media only screen and (max-width: $break-point--380) {
        max-width: initial !important;
    }
}

.shop-vip__container--vip-bottom-text {
    display: none !important;
}

.shop-vip__container--vip-bottom-text-redesign {
    display: block;
    margin: 3rem 0;
    .shop-vip__text--benefits {
        margin: 0 .5rem;
    }
    .shop-vip__icon--benefits {
        color: $color__white;
    }

    @media screen and (max-width: $break-point--568) {
        margin: 2rem 0;
    }
}

.shop-vip__button--benefits {
    width: fit-content;
}

.shop-vip__button--benefits-bclass {
    border-radius: 2rem;
    padding: 0 1rem 0 1.5rem;
    box-shadow: 0 3px 3px 0 #10414e;
}

.shop-vip__container--items {
    max-width: 45rem !important;
}

.shop-vip--list__hint {
    border: none;
    background-color: #ffffff;
    border-radius: $container-border--radius;
    text-shadow: none;
}

.shop-vip--list__hint--text {
    font-weight: $font-weight-bold;
    color: #ff0b0b;

    &.info {
        color: #003743;
    }
}

.shop-vip__container--vip-subscriptions--text-bottom {
    color: #8fd2e1;
}

.shop-vip__container--vip-bottom-text-old-design {
    display: none;
}
.menu--dropdown__elements--container {
    background-color: $color__white;
    border: none !important;
    box-shadow: 0 0 1.5rem .3rem #031e2472;
}

.menu--dropdown--item--wrapper-host {
    p {
        color: #19535f !important;
        text-decoration: none !important;
    }
    .bc__button--icon-cmp {
        fill: #19535f !important;
    }

    &:hover {
        background-color: lighten(#19535f, 65%) !important;
        border-radius: $radius--default;
        p {
            color: #19535f !important;
            text-decoration: none !important;
        }
        .bc__button--icon-cmp {
            fill: #19535f !important;
        }
    }
}
.notification-sign-host {
    font-size: 1.1rem;
    height: 1.75rem;
    min-width: 1.75rem;
}

.notification-sign__wrapper {
    background: #ff002a;
    font-weight: $font-weight-bold;
}

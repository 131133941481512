.cgr__wrapper {
    padding: .5rem;
    &.expanded {
        background-color: $container-dark-background-color !important;
    }
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.cgr__container--countdown {
    border-radius: $border--radius;
}

.cgr__wrapper--header {
    background-color: transparent !important;
}
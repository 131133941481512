.ranked-games-leaderboard-list__container {
    @include ranked-tiles-colors((border-color: #1f525c, bg-color: #1f525c, inner-padding: 0, height: 100%));
    border-radius: 0 0 $container-border--radius $container-border--radius;
}

.ranked-games-leaderboard-list__title-text {
    color: #d6fff8;
    text-shadow: 0 -1px 0 #fafeff, 0 1px 0px #6bb6a5, 0 2px 0px #123a47;
}

.ranked-games-leaderboard-list__title {
    border-radius: 10rem;
    background: linear-gradient(#183349, #2b6574);
    box-shadow: 0 -4px 10px 1px #4595b8;
}

.ranked-games-leaderboard-list__item {
    color: #fabd3c;
    text-shadow: 0 1px 0px #000;
}
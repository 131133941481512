.notification-wrapper__container--main {
    &.gifts,
    &.invites,
    &.likes,
    &.likesandratings,
    &.club,
    &.system {
        @include coloredContainer($container-dark-background-color,
            $container-dark-upper-border-color,
            $container-dark-inner-shadow-color,
            $color__black);
    }

    &.highlighted {
        background-color: #00678b !important;
    }
}
.leaderboards__tabs--scroll {
    background-color: $color--white;
}

.leaderboards__tabs--scrollbar {
    background-color: #04a3d4 !important;
}

.leaderboard__top-tabs__selected {
    color: #04a3d4 !important;
}

.leaderboard__top-tab__button {
    width: 10rem;
    @media screen and (max-width: $break-point--768) {
      height: 3.5rem !important;
    }
    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}

.leaderboard__top-tab__button-bclass {
    background-color: transparent !important;
    margin: 0;
    width: 100%;
    transition: none !important;
    &:hover {
        color: $color--white;
    }
    &.leaderboard__top-tabs__selected {
        background-color: transparent !important;
    }
}

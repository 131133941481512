.ranked-games-progress-bar {
    border-radius: $container-border--radius;
}

.ranked-games-progress-bar {
    background-color: #183c4b;
}

.ranked-games-progress-bar-count {
    background: linear-gradient(#255968, #357081);
}

.ranked-games-progress-bar-delimiter {
    background-color: #306879;

    &.isCompleted {
        background-color: #1d3800;
    }
}
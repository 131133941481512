$color-selected: #293d00;
$color-completed: #addc47;

.progressive-ads__bar-wrapper {
    border: none;
    background-color: #165462;
    box-shadow: 0 2px 1px #307c8c;
}

.progressive-ads__outer--baloon {
    border: none;
    background-color: #165462;
    box-shadow: 0 1px 2px 0px rgb(0 0 0 / 30%) inset, 0 2px 1px #307c8c;
}

.progressive-ads__inner--baloon {
    background-color: #062b33;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0 1px 1px #367f90;

    &.selected {
        background-color: $color-completed;
        box-shadow: 0px 1px 0px 0px #f9f66b inset;
    }
}

.progressive-ads__bar-inner {
    background-color: #062b33;
    box-shadow: 0 1px 1px #367f90;

    &.completed {
        box-shadow: none;
        background-color: $color-completed;
    }
}

.progressive-ads__number {
    color: #267485;
    font-weight: $font-weight-bold;
    font-style: italic;

    &.selected {
        color: $color-selected;
    }
}

.progressive-ads__tick {
    font-size: 2em;
    color: $color-selected;
}

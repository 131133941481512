.list-users-box__container--list-title {
    &:before {
        display: none !important;
    }
}

.list-users-box__container--delete-button {
    color: $color__white;
}

.list-users-box__container--action-button {
    color: $color__white;
}
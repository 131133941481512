.profile__container {
    padding: 0 2%;
}

.profile__container--buttons {
    padding: 0 !important;
}

.cp__image--logo {
    margin: .5rem 0;
}

.profile__container--completed--challenges {
    padding: 2% 0 !important;
}
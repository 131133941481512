.avatars__matching__text--hint-paragraph {
    width: 30%;
}

.avatars__matching__text--hint-paragraph__container {
    flex-direction: row !important;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.matching__label--bet {
    &.tier1 {
        text-shadow: 0 .5rem .5rem #0000006e;
    }

    font-weight: $font-weight-black !important;
    &:before {
      display: none;
    }
}

.avatars__matching__text--middle__text {
    display: none !important;
}

.avatars__matching__text--middle__svg {
    display: flex !important;
}

.avatars__matching__text-hint-text__seconds {
    font-size: $text__size--large;

    @media only screen and (max-height: $break-point--480) {
        font-size: $text__size--normal;
    }
}

.avatars__matching__text-hint-text__label {
    font-size: $text__size--normal;
    font-weight: $font-weight-light;

    @media only screen and (max-height: $break-point--480) {
        font-size: $text__size--small;
    }
}

.avatars__matching__container--my-avatar {
    border: none !important;
}

.avatars__matching__text--hint-paragraph {
    &.tier0-5 {
        > span {
            color: #28606f !important;
        }
    }

    &.tier1 {
        > span {
            color: #ffc8a6 !important;
        }
    }

    &.tier2 {
        > span {
            color: #232323 !important;
        }
    }

    &.tier3 {
        > span {
            color: #432a07 !important;
        }
    }

    &.tier4 {
        > span {
            color: $color__white !important;
        }
    }

    &.tier5 {
        > span {
            color: #ffbc00 !important;
        }
    }

    &.tier6 {
        > span {
            color: #ffbc00 !important;
        }
    }
}

.avatars__matching__text--middle__vs--icon {
    color: $color__white;

    &.tier0-5 {
        color: #28606f;
        filter: drop-shadow(0 .5rem .4rem #28606f);
    }

    &.tier1 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #232323);
    }

    &.tier2 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #232323);
    }

    &.tier3 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #232323);
    }

    &.tier4 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #232323);
    }

    &.tier5 {
        color: $color__white;
        filter: drop-shadow(0 .5rem .4rem #232323);
    }
}

.avatars__matching__container--middle {
    @extend %text;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background: #267485;
    &.tier0-5 {
        background: linear-gradient(180deg, #f0f5e5, #a3ad97) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.tier1 {
        background: linear-gradient(180deg, #8b6552, #312220) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.tier2 {
        background: linear-gradient(180deg, #eeeeee, #a2a2a2, #1b1c1e) !important;
        filter: drop-shadow(0 .7rem .6rem #232323);
    }

    &.tier3 {
        background: linear-gradient(180deg, #fed42c, #ffa025, #653e0b) !important;
        filter: drop-shadow(0 .7rem .6rem #653e0b);
    }

    &.tier4 {
        background: linear-gradient(180deg, #88a243, #5b5f0b) !important;
        filter: drop-shadow(0 .7rem .6rem #000000);
    }

    &.tier5 {
        background: linear-gradient(180deg, #5c00d3, #0d001f) !important;
        filter: drop-shadow(0 .7rem .6rem #230055);
    }

    &.tier6 {
        background: linear-gradient(180deg, #45494d, #000000) !important;
        filter: drop-shadow(0 .7rem .6rem #000000);
    }
}

.avatars__matching__bet__icon {
    fill: #232323 !important;
    margin: 0 1rem;
}

.avatars__matching__container--my-avatar {
    border-radius: 1rem;
}
$color-tab-selected: #48d6ff;

.profile__wrapper {
    background: transparent;
}

$color-tab-highlighted: #eaab00;
$color-tab-selected: #48d6ff;

.profile__tabs-style {
    color: $color__white;
    height: 3rem;

    > p {
        font-size: 1.4rem !important;
        color: $color__white;
    }

    &.selected {
        color: $color-tab-selected;

        > p {
            color: $color-tab-selected;
        }
    }

    &.highlighted {
        color: $color-tab-highlighted !important;

        > p {
            color: $color-tab-highlighted !important;
        }
    }

    .bc__button--icon-cmp {
        display: none !important;
    }
}

.profile__top-tabs__scroll {
    background-color: $color__white;
}

.profile__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.profile-top-tabs__list-item--navigation {
    width: 9rem;

    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}

.profile__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    width: 100%;
    margin: 0;
    transition: none !important;
    &.top-tabs__selected {
        color: $color-tab-selected !important;
        background-color: transparent !important;

        > p {
            color: $color-tab-selected !important;
        }
    }
}

.profile-top-tabs__tab {
    height: 100% !important;
}
$color-tab-highlighted: #eaab00;
$color-tab-selected: #48d6ff;

.club-page__top-tabs__scroll {
    background-color: $color__white;
}

.club-page__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.club-page__top-tabs__scroll {
    background-color: $color__white;
    .top-tabs__scrollbar {
        background-color: $color-tab-selected;
    }
}

.club-page__list-item--navigation {
    width: 10rem;
}

.club-page__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    width: 10rem;
    margin: 0;
    transition: none !important;
    &.top-tabs__selected {
        color: $color-tab-selected !important;
        background-color: transparent !important;

        > p {
            color: $color-tab-selected !important;
        }
    }
}

.club-page__top-tabs__tab {
    height: 100% !important;
}
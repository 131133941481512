$leaderboard-tab--bg-color-first: #48d6ffa6;
$leaderboard-tab--bg-color-second: transparentize( $color__black, .75);

.container-leaderboard__container--tabs {
    >.container-leaderboard__container--tab {
        margin: 0 2.5px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.container-leaderboard__container--tab {
    border: none !important;
    background: linear-gradient($leaderboard-tab--bg-color-first, $leaderboard-tab--bg-color-second);

    &:not(.selected) {
        background: transparentize( $color__black, .5);
    }
}

.container-leaderboard__container--leaderboard {
    border: none !important;
}
$icon-accent: #2b6775;

.container-leaderboard__container--users {
    padding: 0 5% !important;
    padding-top: 1rem;
    @media only screen and (max-width: $break-point--1280) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
    @media only screen and (max-width: $break-point--640) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
    @media only screen and (max-width: $break-point--580) {
        padding: 0 !important;
        padding-top: 1rem;
    }
    @media only screen and (max-width: $break-point--380) {
        padding: 0 5% !important;
        padding-top: 1rem;
    }
}

.container-leaderboard__container--user {
    width: 12.5% !important;

    @media only screen and (max-width: $break-point--1366) {
        width: 14% !important;
    }

    @media only screen and (max-width: $break-point--768) {
        width: 16.66% !important;
    }
    @media only screen and (max-width: $break-point--530) {
        width: 20% !important;
    }
    @media only screen and (max-width: $break-point--480) {
        width: 21% !important;
        margin-left: 2%;
        margin-right: 2%;
    }
    @media only screen and (max-width: $break-point--380) {
        width: 30.33% !important;
        margin-left: 1.5%;
        margin-right: 1.5%;
    }
}

.container-leaderboard__container--tab {
    font-weight: $font-weight-bold;
}

.container-leaderboard__custom-select {
    background: $black__transparent--03;
    color: $white__transparent--05;
    cursor: pointer;
    border-radius: $radius--default;
}

.leaderboard__custom-select {
    color: $color--white !important;
}

.custom-select__expand--color {
    color: $color--white !important;
}

.container-leaderboard__custom-select__option {
    background-color: transparent;
    > p {
        color: #003743;
        font-size: $text__size--normal;
        text-decoration: none;
    }

    &.container-leaderboard__custom-select__option__selected {
        background-color: transparent;
        > p {
            color: $popup-accent-1 !important;
        }
    }
    &:hover {
        background-color: #edf0f1;
    }
}

.container-leaderboard--ranked-season-leaderboard-name{
    background-color: #327b8e;
}
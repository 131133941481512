.offer-buy-item--container {
    &.normal {
        @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
    }

    &.golden {
        @include coloredContainer(#3f321c,
        #ceba69,
        #4a3a21,
        #000c14);
    }
}

.offer-buy-item-container--host {
    border-radius: $container-border--radius;
    box-shadow: 0 6px 10px 0px #115264;
}

.offer-item-shine-cmp {
    border-radius: $container-border--radius;
}
.home__custom-select--lang {
    background: $black__transparent--03;
    color: $white__transparent--05;
    cursor: pointer;
    border-radius: $radius--default;
    width: fit-content;
}

.home__custom-select__label {
    color: $color--white !important;
}

.home__custom-select__expand--color {
    color: $color--white !important;
}

.home__custom-select__option {
    background-color: transparent;
    > p {
        color: #003743;
        font-size: $text__size--normal;
        text-decoration: none;
    }

    &.home__custom-select__option__selected {
        background-color: transparent;
        > p {
            color: $popup-accent-1 !important;
        }
    }
    &:hover {
        background-color: #edf0f1;
    }
}
.chat-invite-content__room--type__label {
    font-weight: $font-weight-bold;
}

.chat-invite-content__room--description {
    font-weight: $font-weight-semibold;
    color: $color__chips;
    margin-bottom: .5rem;
}

.chat-invite-content__players--count__label, .chat-invite-content__tournament--count__label {
    font-weight: $font-weight-bold;
}

.chat-invite-content__players--count, .chat-invite-content__tournament--count {
    font-weight: $font-weight-semibold;
    color: $color__chips;
}

.chat-invite-content__info {
    margin: 0 !important;
    flex-wrap: wrap;
}

.chat-invite-content__info-box {
    margin: .5rem auto !important;
}
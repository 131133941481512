$shop-item-popup-info-currency-color: #21cae0c4;

.shop-item-info__container--item-info {
    .item-image {
        padding: 4rem 0 1rem 0;
        >img {
            min-height: initial !important;
        }
    }
}

.shop-item-info__container--price {
    margin: .5rem 0 0 0 !important;
    .price {
        font-size: 2rem !important;
        .currency {
            font-size: inherit !important;
            color: $shop-item-popup-info-currency-color;
        }
    }
}

.with-price {
    .price {
        font-size: $text__size--large !important;
    }
}

.item--phrases__container {
    background-color: transparent;
}

.item--phrase {
    border: none !important;
    border-top: 1px solid #b234ff !important;
    background-color: #591ab7 !important;
    padding: 1rem !important;
    color: $color--white !important;
}
.ranked-stats-rating-container {
    background-color: rgb(5 51 67);
    border: none;
    box-shadow: 0 2px 0 #329eb4, 0 -2px 0 #041e29;
}

.ranked-stats-rating-container__rating--label-cclas {
    background: #1d6170 !important;
    box-shadow: inset 0 2px 0 #2e90a5, inset 0 1px 7px 1px transparent;
}

.ranked-stats-rating-container__rating--label-text {
    color: $color__white;
}
.gf__container--new-topic {
  background-color: #efefef;
  align-items: flex-start !important;
}

.gf__button--new-topic {
    width: 90% !important;
    height: 5rem !important;
    box-shadow: 0 10px 15px 30px rgb(255 255 255 / 90%);
    background-color: $color__white;
}

.gf__button--new-topic--bclass {
    box-shadow: 0 10px 15px rgb(83 103 39 / 70%);
    color: #3f501b !important;

    > p {
        color: #3f501b !important;
    }
}

.gf__button--new-topic--bclass__share {
  box-shadow: 0 10px 15px #1e4850b3;
}


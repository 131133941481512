/**********************************
*      Matching skin              *
**********************************/

$cup-image: imageUrl('matching/tournaments_matchmaking.png');
$bling-bling: imageUrl('lobby/bling_bling.png');

$cup-shadow-color: #53e4f6;
$cup-shadow-inner-color: #00000036;
$cup-container-color: #00000021;

.blitz__animation__cup {
    display: none;
}

.blitz__animation__cup--new-design {
    display: block;
    width: 15rem;
    height: 15rem;
    margin: 0 auto;
    background-color: $cup-container-color;
    box-shadow: inset 0px 1px 0px 0px $cup-shadow-inner-color,
    inset 0px -1px 0 $cup-shadow-color, 0 0 3px 3px #2a8a9c;
    border-radius: 50%;
}

.blitz__animation__cup--new-design-cup {
    @include background-image($cup-image, 100%, 100%, left, center);
    background-size: auto 100%;
    position: relative;
    padding: 0.5rem;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: 90%;
    border-radius: 50%;
    box-shadow: 0 0 3px 3px #1b798c;

    &:after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        content: '';
        display: block;
        @include background-image($bling-bling, 100%, 100%, left, center);
    }
}
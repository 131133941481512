.matching__wrapper {
    background-color: $popup--backdrop;
}

.matching__container {
    box-shadow: 0 0.5rem 1.2rem 0.1rem #000000;
    border: none !important;

    &.tier0-5 {
        background: linear-gradient(180deg, #f0f5e5, #a3ad97) !important;
        border-top: .1rem solid #fefefd !important;
    }

    &.tier1 {
        background: linear-gradient(180deg, #8b6552, #312220) !important;
        border-top: .1rem solid #f4c09c !important;
    }

    &.tier2 {
        background: linear-gradient(180deg, #eeeeee, #a2a2a2, #1b1c1e) !important;
        border-top: .1rem solid #ffffff !important;
    }

    &.tier3 {
        background: linear-gradient(180deg, #fed42c, #ffa025, #653e0b) !important;
        border-top: .1rem solid #fffb54 !important;
    }

    &.tier4 {
        background: linear-gradient(180deg, #88a243, #5b5f0b) !important;
        border-top: .1rem solid #f4f680 !important;
    }

    &.tier5 {
        background: linear-gradient(180deg, #5c00d3, #0d001f) !important;
        border-top: .1rem solid #af00fb !important;
    }

    &.tier6 {
        background: linear-gradient(180deg, #45494d, #000000) !important;
        border-top: .1rem solid #848b93 !important;
    }
}

.matchmaking__title-bar {
    display: none;
}

.matchmaking-popup__button__cancel {
    width: 28rem !important;
    margin: 0 auto 3.5rem !important;
    height: 4rem;
    max-width: 90%;
}

.matchmaking__title {
    display: flex !important;
    width: 100%;
    justify-content: center;
    @extend %text;
    margin: 2rem 0;
    font-size: 2.2rem;
    font-weight: $font-weight-bold;

    &.tier0-5 {
        color: #28606f;
    }

    &.tier1 {
        color: $color__white;
        text-shadow: 0px .2rem .5rem rgba(0, 0, 0, 0.433);

    }

    &.tier2 {
        color: #232323;
    }

    &.tier3 {
        color: #432a07;
    }

    &.tier4 {
        color: $color--white;
    }

    &.tier5 {
        color: #ffbc00;
    }

    &.tier6 {
        color: $color--white;
    }
}

.gifts-tab__container--premium-gifts {
    border: none;
}

.gifts-tab__text--premium-gifts {
    color: #48d6ff;
}

.gift-container__container--gift {
    border-radius: 1.5rem;
    .gift-container__wrapper--gift-item {
    //   height: 10rem;
      background-color: #133748;
      border-top: .2rem solid #15465b;
      border-radius: 1.5rem;
      overflow: hidden;
        &.in-game {
            .image-container {
                background-color: #0f5b78;
                border-top: .2rem solid #1eb6f0;
            }
        }
    }
}

.image-container {
    @include aspect-ratio(1,1);
    margin: 1rem 1.5rem !important;
}

.gift-container__wrapper--gift-item__premium {
    background: url(imageUrl("/shop/premiumGiftBackground.png"));
    background-color: transparent;
    background-size: cover;
    border-top: none !important;
    .image-container {
        background-color: transparent !important;
        border-top: none !important;
    }
}

.gift-container__container--gift {
    width: 100%;
    margin: 0 !important;
}

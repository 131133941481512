:host {
    height: 100%;
}

.top-tabs__list--navigation {
    background-color: transparent;
}

.top-tabs__list-item--navigation {
    height: 3rem !important;
    &.separator {
        &:before {
            background-color: $accent--1 !important;
        }
    }
}

.top-tabs__container--navigation {
    height: auto !important;
}

.top-tabs__scroll {
    height: 0.2rem !important;
    display: block;
    background-color: #a3a3a3;
    position: sticky !important;
    left: 0;
}

.top-tabs__scrollbar {
    background-color: #2d6a77;
    transform: translate(0, -50%);
    height: 0.3rem !important;
}

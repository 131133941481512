.profile__container--user-content,
.user-info__container--user-info,
.profile__container--club,
.profile__container--images {
    padding: 0 !important;
}

.profile__container--club--title {
    display: flex;
    justify-content: center;
    align-items: center;
}

.user-info__container--remaining-booster {
    color: #4c2b00 !important;
}

.profile__container--club {
    margin: 1rem 0;
}

.profile__container--images {
    margin: .5rem 0 !important;
}

.user-info__container--other-info {
    background-color: #00232aa3 !important;
}
@mixin popupContainer($container-upper-border-color,
$background-inner-shadow, $background-color) {
    box-shadow: inset 0 1px 0 0 $container-upper-border-color,
    inset 0 0 30px 10px $background-inner-shadow;
    background-color: $background-color;
    border-radius: $container-border--radius;
}

@mixin coloredContainer($container-background-color,
$container-upper-border-color, $container-inner-shadow-color,
$container-border-bottom-color, $container-background-color-second: "") {
    @if ($container-background-color != "") {
        @if ($container-background-color-second != "") {
            background: linear-gradient(0deg, $container-background-color-second 0%, $container-background-color 100%) !important;
        } @else {
            background-color: $container-background-color !important;
        }
    }
    box-shadow: inset 0 1px 0 0 $container-upper-border-color,
    inset 0 1px 7px 1px $container-inner-shadow-color;
    border: none;
    border-bottom: solid 1px $container-border-bottom-color;
    border-radius: $container-border--radius;
}

@mixin scrollbar(
    $width: 0.2rem,
    $height: 0.2rem,
    $color: $accent--1,
    $background: rgba(255, 255, 255, 0.2),
    $thumb-border-radius: 0,
    $darken: 10%,
    $ie: true,
    $background-color: transparent
) {
    @at-root .prime:not(.no-touch) & {
        /* WebKit */
        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 0;
            height: 0;
            display: none;
        }
        /* Firefox */
        scrollbar-width: none;
    }
    @at-root .no-touch & {
            &::-webkit-scrollbar {
                width: $width !important;
                height: $height !important;
                background: $background-color;
                -webkit-appearance: none !important;
            }

            &::-webkit-scrollbar-button {
                width: 0 !important;
                height: 0 !important;
            }

            &::-webkit-scrollbar-thumb {
                background: $color !important;
                border: none !important;
                border-radius: $thumb-border-radius !important;
                width: $width !important;
            }

            &::-webkit-scrollbar-thumb:hover {
                background: $color !important;
            }

            &::-webkit-scrollbar-thumb:active {
                background: $color !important;
            }

            &::-webkit-scrollbar-track {
                background: $background !important;
                border: none !important;
                border-radius: 0 !important;
            }

            &::-webkit-scrollbar-track:hover {
                background: $background !important;
            }
            &::-webkit-scrollbar-track:active {
                background: $background !important;
            }
            &::-webkit-scrollbar-corner {
                background: transparent !important;
                @if ($ie) {
                    scrollbar-highlight-color: $color !important;
                    scrollbar-face-color: $color !important;
                    scrollbar-3dlight-color: $color !important;
                    scrollbar-shadow-color: $color !important;
                    scrollbar-darkshadow-color: $background !important;
                    scrollbar-track-color: $background !important;
                    scrollbar-arrow-color: $background !important;
                }
            }
    }
}
@mixin scrollbar-default($width, $color: rgba(255, 255, 255, .2), $background: rgba(0, 0, 0, .2), $darken:10%, $ie: true) {
    /* WebKit */
    &::-webkit-scrollbar {
        width: 0;
        height: 0;
    }
    /* Firefox */
    scrollbar-width: none;
}

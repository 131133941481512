.cashback--tile__content--container {
    box-shadow: inset 0px 3px 3px rgb(255 255 255 / 35%);
    border-bottom: .2rem solid #3f1f9a;
    border-radius: $border--radius;
    justify-content: space-around;
}


.cashback--tile__text {
    display: initial !important;
    max-width: 70%;
}

.cashback--tile__text--container {
    position: relative !important;
    top: 0;
    left: 0;
    width: fit-content;
    height: 100%;
    max-width: 50%;
    justify-content: space-around !important;
    align-content: space-between;
    text-transform: uppercase;
    padding: 0.5rem 1rem 0rem !important;
    height: 100%;
    background-color: transparent !important;
    display: flex !important;
    flex-flow: column nowrap !important;
    &.with__progress-bar {
        height: calc(100% - 3.5rem) !important;
        @media screen and (max-width: $break-point--768) {
            height: calc(100% - 1.5rem);
        }
    }
    @media screen and (max-width: $break-point--680) {
        max-width: 100%;
    }
    .cashback--tile__text,
    .cashback--tile__text--name {
        font-size: 3rem !important;
        text-shadow: 0 1px 0px #d9a5a5, 0 2.5px 0px #b500ff, 0 9px 12px #4e0fa8;
        line-height: 2.4rem !important;
        text-align: initial !important;
        font-weight: $font-weight-extra-bold !important;
        @media screen and (max-width: $break-point--1440) {
            font-size: 2.2rem !important;
        }
    }

    .cashback--tile__timer {
        display: initial !important;
        text-align: initial !important;
        font-size: 1.3rem !important;
        height: auto !important;
        color: $color__white;
        justify-content: unset;
        font-weight: $font-weight-semibold;
        text-shadow: 0 2px 2px rgba(0, 0, 0, 0.5);
        > span {
            font-size: 1.35rem !important;
        }

        @media screen and (max-width: $break-point--568) {
            font-size: $text__size--small !important;

            > span {
                font-size: $text__size--small !important;
            }
        }
    }

    .cashback--tile__button {
        margin: initial !important;
    }
}

.cashback--tile__progress {
    height: 2.5rem;
    font-size: $text__size--normal;

    @media screen and (max-width: $break-point--568) {
        height: 1.8rem;
        font-size: $text__size--smaller;
    }
}

.cash-back__restriction-cover {
    border-radius: $border--radius;
    background-color: $black__transparent--08;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 3rem !important;
}

.cashback-tile__timer__container {
    width: fit-content;

    @media screen and (max-width: $break-point--1440) {
        font-size: 0.8rem;
    }
}
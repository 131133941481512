.item-box__label-with-elevated-price {
    background: linear-gradient(0deg, #072f3d, #206e80) !important;
    border-bottom: 1px solid black !important;
    box-shadow: 0px -1px 0px 0px #34b5d2 !important;
}

@media screen and (max-width: $break-point--380) {
    .item-box__item-label-for-deck {
        font-size: 1.1rem !important;
    }

    .item-box__item-label-for-frame {
        font-size: 1rem !important;
    }

    .item-box__item-label-for-frame {
        font-size: 1rem !important;
    }
}
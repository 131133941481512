$background-color: #267485;
$background-inner-shadow: #3096a4;
$container-bonus-upper-border-color: #21cae0;

.mini-game-double-up__container {
    border: none !important;
    background: none;
    @include popupContainer($container-bonus-upper-border-color,
        $background-inner-shadow, $background-color);
}

.mini-game-double-up__image {
    height: 10rem !important;
}

.mini-game-double-up__won--text {
    text-shadow: 1px 3px 1px #1a5460;
}

.mini-game-double-up__image {
    height: 10rem !important;
}

.mini-game-double-up__reward__amount {
    font-weight: $font-weight-black;
    font-size: $text__size--x-large;
    text-shadow: 1px 3px 1px #1a5460;
    font-style: italic;
}
.item_required-container {
    @include scrollbar();
}

.item_required-show-offer-benefits {
    color: $color__white;
    width: max-content;
    > p {
        font-size: $text__size--large;
        font-weight: $font-weight-bold;
    }
}


.item_required-message {
    font-size: $text__size--large;
    font-weight: $font-weight-bold;
    @media only screen and (max-width: $break-point--480) {
        font-size: $text__size--normal;
    }
}

.item_required-show-offer-benefits__button {
    margin-left: auto;
    margin-right: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.item_required-vip--list__hint {
    border: none;
    background-color: #ffffff;
    border-radius: $container-border--radius;
}

.item_required-vip--list__hint--text {
    font-weight: $font-weight-bold;
    color: #ff0b0b;
    font-size: $text__size--normal;
    padding: 1rem;
}

.item_required-vip__container--vip-subscriptions-top-container {
    text-align: center;
    padding: 2rem;
}

.item_required-vip__container--vip-subscriptions--text-link {
    font-size: $text__size--normal;
    font-weight: $font-weight-bold;
    text-decoration: underline;
    cursor: pointer;
}

.item-store-offers__container--vip-subscriptions--text-bottom {
    color: #8fd2e1;
}
.offer-buy-vip-status-text {
    color: #f2a521;
    text-shadow: 0px -1px #ffff42, 0 2px 2px #1e1f12;
}

.buy--offer__premium-benefits {
    >.buy--offer__premium-benefits__text {
        color: #f5a724;
        text-shadow: 1px 1px #000000;
    }
}
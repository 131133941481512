.chips-item--img-ontainer {
    position: relative;
    > img {
        max-height: unset !important;
    }
}

.chips-item__text-value__tier-bonus__happy-hour {
    &.new--price { 
        color: #9ef000;
    }
}
$dropdown-background-color: #267485;
$dropdown-background-inner-shadow: #3096a4;
$dropdown-container-upper-border-color: #21cae0;

.dropdown__container {
    border: none !important;
    background: none;
    border-radius: $container-border--radius !important;
    @include popupContainer($dropdown-container-upper-border-color,
        $dropdown-background-inner-shadow, $dropdown-background-color);

    &:after {
        display: none;
    }
}

.dropdown__wrapper {
    @include scrollbar();
}
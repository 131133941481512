$color-vip-value-per-day: #b2ebff;
$color-vip-value-per-day-golden: #ffd541;
$color-vip-value-first: #ffcb64;
$color-vip-value-second: #916100;
$color-vip-value-shadow: #e6cc22;

.vip-item--container {
    &.golden {
        .vip-item--price-per-day {
            color: $color-vip-value-per-day-golden;
        }
    }
}

.vip-item--img-ontainer {
    height: 2.5em !important;
    &.premium {
        height: 3.5em !important;
    }
}

.vip-item--price-per-day {
    color: $color-vip-value-per-day;
}

.vip-item--save-from-offer {
    font-weight: $font-weight-bold;
    color: $color__white;
}

.vip-days-amount,
.vip-days-label {
    background: linear-gradient($color-vip-value-first, $color-vip-value-second);
    background-clip: text;
    color: transparent;
    font-weight: $font-weight-extra-bold;
    filter: drop-shadow(0 -1px $color-vip-value-shadow);
}

.vip-days-amount {
    font-size: 3rem !important;
}

.vip-days-label {
    font-size: 2rem !important;
}
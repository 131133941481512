.wins-ratio-box__wrapper {
    @include container--colored($container-dark);
    border: none;
}

.wins-ratio-box__container--tournament-wins {
    @include container--colored($color__black);
    border: none;
}

.wins-ration-box__button--reset {
    color: $color__white;
}
.custom-blitz-table--container {
    background-color: #257384;
    border: none !important;
    padding: 0;
    border-radius: 1.6rem;
    border-top: 1px solid #31b3ba !important;
    width: calc(100% - 2rem) !important;
}

.custom-blitz-table--header {
    padding: 1rem;
    font-weight: $font-weight-extra-bold;
    font-size: $text__size--large !important;
    font-style: italic;
    background-color: #0a5566;
    border-radius: 1.6rem 1.6rem 0 0;
}

.custom-blitz-table__state {
    background-color: #3c95a7;
    border-radius: 1.6rem !important;
    padding: .5rem 2rem;
    color: #286b7a !important;
    font-weight: $font-weight-bold;
    font-size: $text__size--large;
    margin-bottom: 1.5rem;
    @media screen and (max-width: $break-point--1024) {
        font-size: $text__size--normal !important;
    }
}

.custom-blitz-table--container {
    &.finalTable {
        background-image: url(imageUrl("blitz-tournament/final_table_background.png"));
        background-size: cover;
        border-top: 1px solid #fff190!important;
        > .custom-blitz-table--header {
            background-color: #0000000a !important;
            border-bottom: 1px solid #3010001f !important;
            color: #ab3b00 !important;
        }
        > .custom-blitz-table__state {
            background-color: #ffd25f;
            border-radius: 1.6rem !important;
            color: #d7933a !important;
        }
        .custom-blitz-table--team__colon {
            color: #ab3b00 !important
        }
        .custom-blitz-table--avatar__result {
            color: #ab3b00 !important
        }
    }
}

.custom-blitz-table--avatar__result {
    bottom: - 0.5rem !important;
}

.custom-blitz-table--wrapper-host {
    position: relative;
}

.custom-blitz-table__laurel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 101%;
    height: 90%;
    background-image: url(imageUrl("blitz-tournament/final_table_laurel.png"));
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.with--footer {
        top: calc(50% - 3rem);
        height: calc(90% - 5rem);
    }
}

.custom-blitz-table__particles {
    position: absolute;
    top: -10%;
    left: 50%;
    transform: translateX( -50%);
    width: 50%;
    height: 20%;
    background-image: url(imageUrl("blitz-tournament/final_table_particles.png"));
    background-size: cover;
}

.custom-blitz-table__winners-info__footer {
    color: #072f3d;
    border: none !important;
    background-color: $color__white;
    font-weight: $font-weight-semibold;
    font-style: italic;
    > span {
        color: #0086a3 !important;
        font-weight: $font-weight-bold;
    }
}
.edit-profile__custom-select {
    background: $black__transparent--03;
    color: $white__transparent--05;
    cursor: pointer;
    border-radius: $radius--default;
}

.edit-profile__custom-select__option {
    background-color: transparent;
    > p {
        color: #003743;
        font-size: $text__size--normal;
        text-decoration: none;
    }

    &.edit-profile__custom-select__option__selected {
        background-color: transparent;
        > p {
            color: $popup-accent-1 !important;
        }
    }
    &:hover {
        background-color: #edf0f1;
    }
}

.edit-profile__selected-option {
    color: $color--white;
}

.edit-profile__custom-select__extend--button {
    color: $color--white !important;
}

.edit-profile__container--main-group {
    &.avatar {
        flex: 100% !important;
        padding: 0 !important;
    }
}

.progressive-ads-bonus__reward-wrapper {
    border: none;
    overflow: visible;
    background: transparent;

    &.current {
        padding: 0.5em;
        background-color: #0c3b4b;
        border-bottom: 1px solid #8be7fc;
        border-radius: 1.5rem;
        box-shadow: 0 0px 3px 2px rgb(0 0 0 / 40%) inset;

        @media screen and (max-width: $break-point--568) {
            border-radius: 2vh;
        }
    }
}

.progressive-ads-bonus__reward {
    @include aspect-ratio(0.725, 1);
    width: auto !important;
    height: auto !important;
    border: none;
    background-color: transparent;
    background-image: url(imageUrl("free-rewards/container-reward-progressive.png"));
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 10%;

    &.current {
        box-shadow: 0 0 18px 3px #55d4e4;
    }

    &.locked {
        filter: brightness(0.6);
        box-shadow: 0 15px 18px 0px rgb(25 55 78 / 60%);
    }

    &.claimed {
        background-image: none;
        background-color: #062b33;

        &:after {
            position: absolute;
            content: "";
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            display: block;
            background-color: #062b33;
            opacity: 0.8;
        }
    }
}

.progressive-ads-bonus__reward--booster {
    font-weight: $font-weight-bold;
    text-align: center;
}

.progressive-ads-bonus__reward--icon {
    font-size: 2em;
    color: #5ad2ee;

    &.tick {
        border: 3px solid #5ad2ee;
        border-radius: 50%;
    }
}

.progressive-ads-bonus--button-bclass {
    box-shadow: 0 2px 3px #000000e6, 0 0 12px 3px #7cca02;
}

.progressive-ads-bonus--button {
    margin-top: .5em;
}

.progressive-ads-bonus--button-bclass>p {
    @media screen and (max-width:$break-point--380) {
        font-size: 0.6rem !important;
    }
}
.invite-friends__wrapper {
    border: none;
    @include coloredContainer($container-dark-background-color,
    $container-dark-upper-border-color,
    $container-dark-inner-shadow-color,
    $color__black);
}

.invite-friends__banner {
    &:after {
        display: none;
    }
}

.invite-friends__title--wrapper {
    background-color: transparent;
}

.invite-friends__banner--img {
    background-size: 165%;
}
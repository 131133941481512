.winners__box-container {
    background-color: transparent !important;
    background-size: cover;
    background-position: center;
    border: none;
    border-bottom: 0.2rem solid #153a48;
    .winners-ribbon-container {
        top: 0 !important;
        height: 12rem !important;
        background-color: transparent !important;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
        transform: translateY(-90%);
        @media only screen and (max-width: $break-point--980) {
            transform: translateY(-70%);
            background-size: contain;
            height: 18rem !important;
        }
    }
    &.first {
        background-image: url(imageUrl("blitz-tournament/firstPlaceBackground.png"));
        .winners-ribbon-container {
            background-image: url(imageUrl("blitz-tournament/firstPlace.png"));
        }
    }
    &.second {
        background-image: url(imageUrl("blitz-tournament/secondPlaceBackground.png"));
        .winners-ribbon-container {
            background-image: url(imageUrl("blitz-tournament/secondPlace.png"));
        }
    }
}

.winners-ribbon {
    height: 0 !important;
}

.winners__box-container {
    border-radius: 1.5rem !important;
}

.winners-prize {
    > .winners-prize-text {
        font-size: $text__size--large !important;
        font-weight: $font-weight-bold;
        color: rgba(0, 0, 0, 0.5);
    }
    > .winners-prize-chips {
        font-size: $text__size--large !important;
        color: $color--chips;
        font-weight: $font-weight-bold;
    }
}

.winner_box-team-player {
    border: none !important;
}
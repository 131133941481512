$background-color: #267485;
$background-inner-shadow: #3096a4;

$color-admin-reward-container-first-gradient-color: #206e80;
$color-admin-reward-container-second-gradient-color: #072f3d;
$color-admin-reward-container-box-shadow-color: #01111a;
$color-admin-reward-container-upper-border-color: #01111a;
$color-admin-reward-text: #ffa600;
$color-tab-highlighted: #eaab00;
$color-tab-selected: #48d6ff;

.free-rewards-popup__popup-container {
    background: none;
    @include popupContainer($container-dark-upper-border-color,
        $background-inner-shadow, $background-color);
}

.free-rewards-popup__container--content {
    @include scrollbar();
    padding-top: 0 !important;
}

.free-reward-box__container--main {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.free-rewards-popup__wrapper {
    background: $popup--backdrop;
}

.free-rewards-popup__content-wrapper {
    --top-tabs-height: 10.7rem !important;
    height: calc(100% - var(--top-tabs-height)) !important;
}

.free-rewards__top-tabs__scroll {
    background-color: $color__white;
    visibility: visible !important;
}

.free-rewards__top-tabs__scrollbar {
    background-color: $color-tab-selected;
    visibility: visible !important;
}

.free-rewards__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    margin: 0;
    transition: none !important;

    >p {
        text-decoration: none;
    }

    &.free-rewards__top-tabs__bclass__selected {
        background-color: transparent !important;

        >p {
            color: $color-tab-selected !important;
        }
    }
}
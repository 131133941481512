.gallery-preview__container--title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: $font-weight-semibold;
    >p {
        height: initial !important;
        text-transform: uppercase;
    }
}

.gallery-preview__wrapper {
  background-color: #1c606f;
}

.gallery-photo__container--single-photo-wrapper {
  background-color: #267485;
}

.gallery-preview__image--gallery-add-photo {
  background-color: #267485 !important;
}

.ranked-room-tile-top-bar {
    // background: linear-gradient(90deg, #212560, #31389b, #212560);
    background: linear-gradient(90deg, #174253, #235568 50%, #174253);
    box-shadow: 0 -2px 0px 0px #277286 inset, inset 0 -1px 4px 0 $black__transparent--07;
}

.ranked-tile-top-bar-curved-path-border__class {
    filter: drop-shadow(0 0px 2px $black__transparent--07);
}

.stop-1 {
    stop-color: #3f95a6;
}

.stop-2 {
    stop-color: #3f95a6;
}

.stop-3 {
    stop-color: #3f95a6;
}

.ranked-tile-top-bar__element {
    color: #4e93b1;

    background-color: #102e3c;
    box-shadow: inset 0px 2px 4px 0px $black__transparent--06,
        0px 1px 0 #2c7b97;
    padding: 1rem;
    border-radius: 10rem;
}

.ranked-tile-top-bar-curved__border {
    height: calc(100% + 3px) !important;
}
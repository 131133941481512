.profile-buttons__container--buttons {
    padding: 0 !important;
}

.profile-buttons__subscribed {
    color: #f2a521;
    text-shadow: 0px -1px #ffff42, 0 2px 2px #1e1f12;
}

.profile-buttons__tooltip-text {
    color: #1d5f6f;
}

.cash-back__info--button--bclass {
    color: white !important;
}
.challenges-dropdown__overlay {
    background-color: $popup--backdrop;
}

.challenges-dropdown__top-tabs__scroll {
    background-color: $color__white;
    visibility: visible !important;
}

.challenges-dropdown__top-tabs__scrollbar {
    background-color: $color-tab-selected;
    visibility: visible !important;
}

.challenges-dropdown__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    margin: 0;
    transition: none !important;

    >p {
        text-decoration: none;
    }

    &.daily-challenges-dropdown__top-tabs__bclass__selected {
        background-color: transparent !important;

        >p {
            color: $color-tab-selected !important;
        }
    }

    &.weekly-challenges-dropdown__top-tabs__bclass__selected {
        background-color: transparent !important;

        >p {
            color: #ff9a00 !important;
        }
    }
}

.weekly-challenges-dropdown__top-tabs__scrollbar {
    background-color: #ff9a00;
    visibility: visible !important;
}

.challenges-dropdown__weekly-challenges {
    background-image: url(imageUrl("backgrounds/bg.svg")), linear-gradient(to bottom, #a2601f, #824a1d, #613719, #422413, #241409);
    background-repeat: repeat;
    background-size: 70%;
}

.challenges-dropdown__container--weekly-challenge .pib__wrapper {
    background-image: linear-gradient(to bottom, #ffc84c, #e6ac3a, #cd9228, #b37815, #9a5f00);
    box-shadow: inset 0 1px 0 0 #fff1a6;
}

.challenges-dropdown__container--weekly-challenge .pib__container--title {
    color: #00222c;
}

.challenges-dropdown__container--weekly-challenge .pib__text--progress-bar {
    color: #b67e1b;
}

.challenges-dropdown__container--weekly-challenge .item-small-box__value-wrapper {
    text-shadow: 1px 2px black;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container.progress-classic :not(.overrided):last-child {
    background-color: #0091ba;
    box-shadow: inset 0px 1px 2px 0px #00ffff;
}

.challenges-dropdown__container--weekly-challenge .progress-bar__progress--container {
    box-shadow: 0px 1px 2px 0px #ffde78 !important;
}

.challenges-dropdown__container--weekly-challenge .pib__svg-icon-cmp {
    color: $color--white;
}

.challenge-box--progress-info-box--skippies-button {
    border-radius: 1rem;
    background-image: linear-gradient(to bottom, #6b00da, #5d01ca, #5001b9, #4301a9, #360099);
    box-shadow: inset 0px -2px 2px 0px #00002a, inset 0px 2px 1px 0px #d100fd;
}
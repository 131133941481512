.fl__text--reward-value {
    &.Chips {
        color: $color__white;
    }

    &.Gems {
        color: $color__white;
    }

    &.ClubShards {
        @include chip-sign(2.4rem, $club-xp, $color__chips);
        color: $color__white;
        &:before {
            left: -2.6rem;
        }
    }

}

.fl__container--values {
    width: 25rem !important;
}

.challenge-progress-info-box--wrapper-weekly {
    background-image: linear-gradient(to bottom, #ffc84c, #e6ac3a, #cd9228, #b37815, #9a5f00);
    box-shadow: inset 0 1px 0 0 #fff1a6;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .challenge-progress-info-box__progress-bar .progress-bar__progress--container.progress-classic :not(.overrided):last-child {
    background-color: #0091ba;
    box-shadow: inset 0px 1px 2px 0px #00ffff;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__progress-bar-data-wrapper .progress-bar__wrapper .progress-bar__progress--container {
    box-shadow: 0px 1px 2px 0px #ffde78 !important;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__text--progress-bar {
    color: #936617;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__challenge-title {
    color: #00222c;
}

.challenge-progress-info-box--wrapper-weekly .challenge-progress-info-box__challenge-icon {
    color: $color--white;
}

.challenge-progress-info-box--wrapper-daily {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.challenge-progress-info-box--wrapper-daily .challenge-progress-info-box__challenge-title {
    color: #03d8ff;
}

.challenge-progress-info-box--wrapper-daily .challenge-progress-info-box__text--progress-bar {
    color: #197e9e;
}
.level-up-rewards--container {
    background-color: #052531;
    border-bottom: 1px solid #10779d;

    &.ready-for-claim {
        background-color: #00709a;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), 0px 1px 0px 0px #00f9fa;
    }
}

.level-up-rewards-container--wrapper {
    background-color: #0a4458;
    border-top: 1px solid transparent;
    border-bottom: 1px solid black;
    box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 0px 0px #21cae0;

    &.ready-for-claim {
        background-color: #00c0db;
        border: 1px solid #b8fe00;
        box-shadow: 0 2px 13px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 5px 4px #2db41b, inset 0 1px 0px 0px #21cae0;
    }
}

.level-up-rewards-icon--claimed-wrapper {
    background-color: #b3df27;
    box-shadow: none;
}

.level-up-rewards-icon--wrapper {
    background-color: #257688;
}

.level-up-rewards-icon--wrapper-secondary {
    &.lock {
        background-color: #104250;
        box-shadow: 0 -1px 0px 0 #3ac5e4;
        background-image: linear-gradient(to bottom, #1c6273, #165262, #114252, #0b3341, #062531);
    }
}

.level-up-rewards-container--overlay {
    background-color: #052531c2;
}

@keyframes level-up-rewards-container-animation {
    0% {
        background-color: #052531;
    }

    25% {
        background-color: #043749;
    }

    50% {
        background-color: #014963;
    }

    75% {
        background-color: #005c7e;
    }

    100% {
        background-color: #00709a;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), 0px 1px 0px 0px #00f9fa;
    }
}

@keyframes level-up-rewards-container-wrapper-animation {
    0% {
        background-color: #0a4458;
        transform: scale(1);
    }

    25% {
        background-color: #0c6178;
    }

    50% {
        background-color: #0b7f99;
        transform: scale(1.03);
    }

    75% {
        background-color: #069fab;
    }

    100% {
        background-color: #00c0db;
        transform: scale(1);
        box-shadow: 0 2px 9px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 5px 4px #2db41b, inset 0 1px 0px 0px #21cae0;
        border: 1px solid #b8fe00;
    }
}

@keyframes level-up-rewards-container-wrapper-fade-out {
    0% {
        background-color: #00c0db;
        box-shadow: 0 2px 4px 0px #1f921e, 0px 8px 13px 2px rgba(0, 0, 0, 0.5), 0px 0px 3px 2px #2db41b, inset 0 1px 0px 0px #21cae0;
    }

    25% {
        background-color: #069fab;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 0px 0px #21cae0;
        border: none;
        border-top: 1px solid transparent;
        border-bottom: 1px solid black;
    }

    50% {
        background-color: #0b7f99;
    }

    75% {
        background-color: #0c6178;
    }

    100% {
        background-color: #0a4458;
        box-shadow: 0 8px 13px 2px rgba(0, 0, 0, .5), inset 0 1px 0px 0px #21cae0;
        border: none;
        border-top: 1px solid transparent;
        border-bottom: 1px solid black;
    }
}

@keyframes level-up-rewards-container-fade-out {
    0% {
        background-color: #00709a;
        box-shadow: inset 0 0 4px 0 rgba(0, 0, 0, .5), 0px 1px 0px 0px #00f9fa;
    }

    25% {
        background-color: #005c7e;
        box-shadow: none;
        border-bottom: 1px solid #10779d;
    }

    50% {
        background-color: #014963;
    }

    75% {
        background-color: #043749;
    }

    100% {
        background-color: #052531;
        box-shadow: none;
        border-bottom: 1px solid #10779d;
    }
}
.level-up-progress-bar--container {
    background-color: #0b5265;
    box-shadow: inset 0px 0px 5px 2px rgba(0, 0, 0, 0.5),
        0 0 1px 2px #50b5c1;
}

.level-up-progress-bar--secondary-wrapper {
    background-color: #047da7;
}

.level-up-progress-bar--initial-wrapper {
    background-color: #0d303b;
    box-shadow: 0 0 2px 2px #2c8999;
}
$close-btn-box-shadow-color: #2e8c9f;
$close-btn-box-shadow-inner-color: #00000036;
$close-btn-container-color: #00000021;

.bcr__container--close {
    background-color: $close-btn-container-color;
    box-shadow: inset 0px 1px 0px 0px $close-btn-box-shadow-inner-color,
    inset 0px -1px 0 $close-btn-box-shadow-color;
}

.bcr__button--close {
    color: $color__white;
}
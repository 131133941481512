$dark-blue: #163849;
$font-color: #004857;

.settings-popup__container--content {
    order: 2;
    width: 100%;
    height: calc(100% - 4rem) !important;
    &.no-tabs {
        height: calc(100% - 5.9rem);
    }
}

.settings-popup__custom-class {
    background: $color__white !important;
    box-shadow: none !important;
}

.settings-popup__title-bar {
    display: none !important;
}

.settings-popup__container--content-inner {
    @include scrollbar(0.2rem, 0.2rem, $popup-font--color, transparent, 0, 10%, true, $popup-light-gray);
}

.settings-popup__container--button-close {
    display: flex !important;
    width: calc(100% - 1rem);
    margin: 0 0.5rem 1rem 0;
    height: 3rem;
    justify-content: flex-end;
}

.settings-popup__button-close {
    width: 4rem;
    margin: 0 !important;
}

.settings-popup__button-close--bclass {
    font-size: $text__size--x-large;
    color: $popup-font--color;
    &:hover {
        color: $popup-accent-1;
    }
}

.settings-popup__tabs__container {
    @include scrollbar();
    @include touch-scroll();
    display: block !important;
}

.settings-popup__container--option {
    &:after {
        display: none !important;
    }
    &.lastEl {
        &:after {
            display: block !important;
            @include separator(#d3dcde);
        }
    }
}

.settings-popup__bottom-container {
    flex-direction: column-reverse;
}

.settings-popup__userId {
    font-size: $text__size--small;
    color: $popup-accent-1;
    > span {
        color: $popup-font--color;
        text-decoration: underline;
        cursor: pointer;
    }

    @media screen and (max-width:$break-point--380) {
        font-size: $text__size--small;
    }
}

.settings-popup__container--copyright {
    color: $dark-blue;
    padding-left: 0 !important;
    font-size: $text__size--small;
    font-weight: $font-weight-bold;
}

.settings-popup__control--button {
    color: $font-color;
    > p {
        color: $font-color;
        font-size: $text__size--small;
    }
}

.settings-popup__social-media-links {
    &:after {
        display: none !important;
    }
}

.settings-popup__container--option {
    &.lastEl {
        &:after {
            @include separator(#d3dcde);
        }
    }
}

.gdpr_info {
    color: $popup-font--color;
}

.settings-popup_privacy-description {
    position: relative;
    color: #454949;
    padding-bottom: 2rem;
    &:after {
        position: absolute;
        @include separator(#d3dcde);
    }
}

.settings-popup__link--privacy {
    color: $popup-font--color;
    font-weight: $font-weight-medium;
    > p {
        span, a {
            color: $popup-accent-1;
        }
        a {
            cursor: pointer;
        }
    }
}

.settings-popup__tabs-style {
    color: #a6c3ca;
    height: 2rem;

    > p {
        font-size: 1.4rem !important;
        color: #a6c3ca;
    }

    &.selected {
        color: $popup-accent-1;

        > p {
            color: $popup-accent-1;
        }
    }
}

.settings-popup__top-tabs__scroll {
    background-color: #a6c3ca;
}

.settings-popup__top-tabs__scrollbar {
    background-color: $popup-accent-1;
}

.settings-popup__link--privacy__terms {
    color: $popup-accent-1;
}

.settings-popup__link--privacy__word {
    color: $popup-font--color;
}

.settings-popup__top-tabs__current {
    > p {
        color: $popup-accent-1 !important;
    }
}

.settings__top-tabs__bclass {
    width: 13rem !important;
    color: #a6c3ca;
    background-color: transparent;
    transition:  none !important;
    > p {
        color: #a6c3ca;
        font-size: $text__size--normal;
        text-decoration: none;
    }
    &.settings-popup__top-tabs__current {
        background-color: transparent;
        > p {
            color: $popup-accent-1 !important;
        }
    }
}

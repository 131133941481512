$events-tile-border-top-color: #f8ea7b;
$events-tile-border-bottom-color: #51691f;

.events-tile__initial {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    box-shadow: inset 0 3px 3px #ffffff59;
    border-bottom: 0.2rem solid $events-tile-border-bottom-color;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: url(imageUrl("lobby/events.png"));
    .events-tile__initial__img {
        background-size: contain;
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center;
        &.events-tile__small-tile {
            background-image: url(imageUrl("lobby/trophy.png"));
            height: 80%;
            
        }
        &.events-tile__extra-small-tile {
            position: absolute;
            background-image: url(imageUrl("lobby/trophyCropped.png"));
            left: 50%;
            top: 1rem;
            transform: translateX(-50%);
            height: 127%;
        }
    }
}

.events-tile__wrapper--texts {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    text-align: center;
    font-size: 2.6rem;
    font-weight: $font-weight-extra-bold !important;
    text-shadow: 0 1px 0px #adba87, 0 2.5px 0px #90d800, 0 9px 12px #54293a;

    text-transform: uppercase;

    > span {
        text-transform: lowercase;
    }

    @media screen and (max-width: $break-point--440) {
        font-size: $text__size--large;
    }
}

.events-tile__leaderboards--name {
    position: absolute;
    top: 0.5rem;
    left: 50%;
    transform: translateX(-50%);
    color: #372305;
}
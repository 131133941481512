.gallery--photo__delete-button,
.gallery--photo__report-button,
.gallery--photo__change-avatar-button {
    color: $color__white;
}

.gallery__container--inner-image {
    .gallery__container--add-button {
        background-color: #1c606f;
    }
}
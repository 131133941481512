$social-media-grandient-light: #2c6a77;
$social-media-grandient-dark: #153546;

.social-media-link_icon {
    transition: opacity .2s linear;
    background: linear-gradient($social-media-grandient-light, $social-media-grandient-dark);
    border-radius: 50%;
    &:hover {
        color: $color__white;
        opacity: 0.8;
    }
}
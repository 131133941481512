$background-color-emoji-item: #0f5b78;

.emoji-items__container--item {
    background-color: #591ab7!important;
    border-top: 0.2rem solid #b234ff!important;
}

.emoji-tems__contaner--title {
    position: relative;
    background-image: none !important;
    > p {
        color: #ffffff61;
        padding: 0 !important;
    }
}

.emoji-items__list {
    padding: 0.5rem 2rem 0.5rem !important;
    > .emoji-items__constainer-list {
      > .emoji-items__container--item--default {
            background-color: #0f5b78;
            border-top: 1px solid #1eb6f0 !important;
            box-shadow: 0.2rem 0.2rem 0 0.1rem $black__transparent--03;
            border-radius: $radius--default;
      }
    }
}
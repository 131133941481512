.training-scroll-tile__title {
    font-size: 2.4rem !important;
    font-weight: $font-weight-black !important;
    color: #233b49;
    line-height: 1.4;
}

.training-scroll-tile__room-separator {
    display: none;
}

.training-scroll-tile__description {
    color: #233a49;

}

.training-scroll-tile__container {
    background: url(imageUrl("lobby/training.png"));
    background-size: cover;
    background-position: center;
    border-radius: $container-border--radius;
}

.training-scroll-tile__icon {
    color: #bcd3de;
    width: 70% !important;
}

.training-scroll-tile__bclass {
   height: 4.5rem !important;
   box-shadow: 0 2px 3px $black__transparent--09;
}

.training-scroll-tile__title_container {
    display: flex !important;
    font-weight: bold;
    font-size: 2rem;
    text-align: center;
    align-items: center;
    width: 100%;
    z-index: 1;
}

.training-scroll-tile__logo {
    position: absolute;
    height: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    width: 65%;
    align-items: center;
}

.training-scroll-tile__room-transactions {
    color: #233b49;
}

.training-scroll-tile__wrapper {
    height: 60%;
    margin-top: 20%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &.with-bet {
        justify-content: space-around;
    }
}

.training-scroll-tile__button {
    margin-top: 0 !important;
    margin-bottom: 19% !important;
}

.training-scroll-tile__wrapper {
    margin-top: 23% !important;
}

.training-scroll-tile-reward__label {
    display: flex;
    flex-direction: row;
}

.training-scroll-tile-reward__value-label {
    margin-right: 0.5rem;
}

.training-scroll-tile__button {
    width: 80% !important;
    margin-left: 10% !important;
    margin-bottom: 19% !important;

    &.with-players-counter {
        margin-bottom: 21% !important;        
        &.isTouch {
            margin-bottom: 21% !important;
            @media screen and (max-width: $break-point--768) {
                margin-bottom: 22% !important;
            }
        }
    }
}

.training-scroll-tile__players-counter {
    padding: 0.8rem !important;
    top: unset !important;
    background-color: #00000098 !important;
    color: $color--white;
    border-radius: 10rem;
    bottom: 1rem;
    width: 10rem;
    left: 50%;
    transform: translateX(-50%);
}
.ranked-stats-element {
    width: 15%;
    &:not(.ranked-team-stats-box-avatars) {
        background-color: transparent !important;
        border-radius: $container-border--radius;
        text-shadow: 0 4px 6px $black__transparent--07;
    }

    &.isFirst {
        padding-left: 1rem !important;
    }

    &.isLast {
        padding-right: 1rem !important;
    }
}

.ranked-team-stats-box {
    background-color: #0b3846;
    padding: 0 0 1rem !important;
    border-radius: 1.5rem;
    border-bottom: .2rem solid #05181e;
}

.ranked-team-stats-box-title-bar {
    align-items: center;
    background: linear-gradient(90deg, #4a273a 10%, #082d39);
    border-radius: 1.5rem 1.5rem 0 0;
    border-top: none;
    box-shadow: inset 0 2px 0 #a0a0a061;
    padding: 0 1rem;
    &.winners {
        border-top: none;
        background: linear-gradient(90deg, #00631d 10%, #082d39);
    }
}

.ranked-team-stats-box-result-text {
    color: #febe00;
}


.ranked-team-stats-box-delimiter {
    width: 2px;
    background: linear-gradient(transparent, #3a4a76,transparent);
    display: block !important;
}

.ranked-team-stats-box-title-bar-text {
    text-transform: uppercase;
}
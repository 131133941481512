$container-bonus-background-color: #072f3d;
$container-bonus-upper-border-color: #14a0b3c7;
$container-bonus-inner-shadow-color: #105e68e3;

$close-btn-box-shadow-inner-color: #00000036;
$close-btn-box-shadow-color: #0a58689e;

.shop-item-popup__container--custom-class {
    background: none;
    @include coloredContainer($container-bonus-background-color,
        $container-bonus-upper-border-color,
        $container-bonus-inner-shadow-color,
        $color__black);
}

.shop-item-popup__container__happy-hour {
    background-image: url(imageUrl('shop/happyHourBackground.png'));
    background-size: cover;
    background-position-y: 30%;
    background-position-x: 50%;
    background-repeat: no-repeat;
}


.shop-item__container--content {
    @include scrollbar();
}

.shop-item__container--buttons {
    width: 90% !important;
    margin: 0 5% 2% 5%;
}

.shop-item-info__container--title {
    display: none !important;
}

.shop-item__separator {
    display: block !important;
    width: 100%;
    height: 6rem;
    background-image: url(imageUrl("shop/separator.png"));
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;

    @media only screen and (max-height: $break-point--480) and (orientation: landscape) {
        display: none !important;
    }
}

.shop-item__navigate-to-shop__button__container {
    margin: 0 auto 1rem !important;
    width: 85% !important;
    @media only screen and (max-width: $break-point--480) {
        width: 85% !important;
    }

    &.with--one--provider {
        width: calc(40% - 2rem)!important;
        @media only screen and (max-width: $break-point--480) {
          width: calc(45% - 2rem) !important;
        }
    }
}

.shop-item__navigate-to-shop__button {
    padding: 0 !important;
}

.shop-item__container--button {
    min-height: 6rem !important;
}
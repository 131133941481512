.rewarded-game-bonus--tile__content--container {
    border-bottom: .2rem solid #fc9155;
    border-radius: $border--radius;
    box-shadow: inset 0px 3px 3px rgb(255 255 255 / 35%);
    background-image: url(imageUrl("lobby/wheeloffortune3.png")) !important;
    &.scratchCard {
        background-image: url(imageUrl("lobby/ScratchCard_1.png")) !important;
    }
    &.wheelOfFortune {
        border-bottom: .2rem solid #6f0346;
        .rewarded-game-bonus--tile__text--container {
            .rewarded-game-bonus__text,
            .rewarded-game-bonus__text--name {
                text-shadow: 0 1px 0px #d30e71, 0 2px 0px #d30e71, 0 3px 0px #620734, 0 9px 12px #620734;
            }
        }
    }
    .rewarded-game-bonus--tile__text--container {
        position: absolute;
        top: 0;
        left: 0;
        width: fit-content;
        height: 100% !important;
        max-width: 75%;
        justify-content: flex-start !important;
        align-content: space-between;
        text-transform: uppercase;
        background-color: transparent !important;
        padding: 0.5rem 1rem !important;
        @media screen and (max-width: $break-point--680) {
            max-width: 95%;
        }
    
        .rewarded-game-bonus__text,
        .rewarded-game-bonus__text--name {
            font-size: 3rem !important;
            text-shadow: 0 1px 0px #c3b87b, 0 2px 0px #ff9e00, 0 3px 0px #805713, 0 8px 6px #873e08;
            line-height: 2.4rem !important;
            text-align: initial !important;
            font-weight: $font-weight-extra-bold !important;
            @media screen and (max-width: $break-point--568) {
                font-size: 2.6rem !important;
            }
            @media screen and (max-width: $break-point--380) {
                font-size: 2.2rem !important;
            }
            @media screen and (max-width: $break-point--320) {
                font-size: 2rem !important;
            }
        }
        .rewarded-game-bonus--title__container {
            height: calc(100% - 3.5rem);
            position: relative;
            width: 100%;
            display: initial !important;
            @media screen and (max-width: $break-point--768) {
                height: calc(100% - 2.5rem);
            }
            .rewarded-game-bonus__text {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                display: initial !important;
                flex-direction: column;
                justify-content: center;
                > span {
                    font-style: italic;
                    text-transform: lowercase;
                }
            }
        }
    
        .rewarded-game-bonus--tile__timer {
            display: initial !important;
            text-align: initial !important;
            font-size: 1.5rem !important;
            height: auto !important;
            font-weight: $font-weight-semibold;
            > span {
                font-size: 1.35rem !important;
            }
        }
    
        .rewarded-game-bonus--tile__button {
            margin: initial !important;
            margin-bottom: .5rem !important;
        }
    }
}


.rewarded-game-tile__restriction-cover {
    border-radius: $border--radius;
    background-color: $black__transparent--08;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
}
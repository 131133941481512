.challenge__container {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
    min-height: initial;
}

.challenge__main--info__title {
    color: #03d8ff;
}

.betting-game-tile__container {
    border-radius: $radius--default;
    justify-content: space-evenly !important;
    padding: 0.5rem 1rem !important;
}

.betting-game-tile__button__container {
    background-color: transparent;
    z-index: 1;
    padding: 0 !important;
}

.betting-game-tile__button {
    margin: 0 !important;
}

.betting-game-tile__logo {
    background-position: right;
}

.betting-game-tile__title {
    display: block !important;
    font-size: 3rem!important;
    font-weight: $font-weight-extra-bold!important;
    line-height: 2.4rem!important;
    z-index: 1;
    text-transform: uppercase;
    margin-left: 0;
    color: #e6e6e6;
    text-shadow: 1px 1px 2px #171175;
    @media screen and (max-width: $break-point--768) {
        margin-bottom: .5rem;
    }
    @media screen and (max-width: $break-point--568) {
        font-size: 2.6rem !important;
    }
    @media screen and (max-width: $break-point--380) {
        font-size: 2.2rem !important;
    }
    @media screen and (max-width: $break-point--320) {
        font-size: 2rem !important;
    }
}

.betting-game-tile__restriction-cover {
    border-radius: $border--radius;
    background-color: $black__transparent--08;
    z-index: 1;
    display: flex !important;
    justify-content: center;
    align-items: center;
    font-size: 3rem !important;
}
.apib__wrapper {
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.apib__container--title {
    color: #03d8ff;
}

.apib__svg-icon-cmp {
    color: $accent--1;
}

.apib__text--progress-bar {
    color: #197e9e;
    font-style: italic;
}
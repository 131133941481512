.hidden__shop__search--bar {
    box-shadow: inset 0 1px 0 0 #072f3d, inset 0 -2px 3px -1px #21cae0;
    border: none !important;
}

.hidden-shop__selector-item {
    background-color: $black__transparent--03;
    border: none;

    &.selected {
        background-color: $black__transparent--07;
    }
}

.list-users-box__container--list-friends-users {
    margin-top: 1rem;
}
$chat__topic-username--color: $main;
$chat__topic-sticky-username--color: $main;
$chat__topic-sticky-background--color: $color__white;
$chat__topic-message--color: $main;
$chat__topic--sticky-message--color: $main;
$chat__icons--color: $accent--1;
$chat__topic-unread-background--color: #d3d2d1;
$chat__topic-background--color: $color__white;
$chat__tpic-username-font-weight: 900;
$chat__topic-unread-username--color: $chat__topic-sticky-username--color;
$chat__topic-unread-message--color: $chat__topic-message--color;

.conversation--container {
    @include container--colored($container-dark);
    background-color: $chat__topic-background--color;
    border: none;
    margin: 1rem 0.5rem !important;

    @include touch-hover() {
        background-color: darken($chat__topic-background--color, 10%);
        border: none;
    }
}

// User & last message
.text--username {
    color: $chat__topic-username--color;
    font-weight: $chat__tpic-username-font-weight;
}

.text--last-message {
    color: $chat__topic-message--color;
}

.conversation--unread {
    @include container--colored($chat__topic-unread-background--color, 0);
    border: none;

    .text--username {
        color: $chat__topic-unread-message--color;
    }

    .text--conv-time {
        color: $chat__topic-unread-message-sent-before--color;
    }

    .text--last-message {
        color: $chat__topic-unread-username--color;
    }

    @include touch-hover() {
        @include container--colored(
            lighten($chat__topic-unread-background--color, 10%),
            0
        );
        border: none;
    }
}

.button--arrow {
    color: $color--chips;
    font-size: $text__size--large;
}

.icon--pin {
    font-size: $text__size--large;
}

.button--delete-format {
    color: $chat__icons--color !important;
}

.button--like-format {
    color: #cd36ab !important;
}

.conversation__icon-text-value {
    color: $chat__topic-message--color !important;
}

.conversation__like--icon {
    color: $chat__topic-message--color !important;
}

.conversation__messages-count-icon {
    color: #04a3d4 !important;
}

.conv--icon {
    color: $color--chips;
}

.button--dropdown__toggle {
    color: $color--chips !important;
}

.chat--conv-icons__icon {
    height: 2rem !important;
    min-height: 2rem !important;
}
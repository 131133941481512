$color-tab-selected: #48d6ff;


.invite-page__top-tabs__scroll {
    background-color: $color__white;
}

.invite-page__top-tabs__scrollbar {
    background-color: $color-tab-selected;
}

.invite-page__list-item--navigation {
    width: 9rem;

    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
}

.invite-page__top-tabs__bclass {
    color: $color--white !important;
    background-color: transparent !important;
    width: 9rem;
    margin: 0;
    transition: none !important;
    @media screen and (max-width: $break-point--568) {
        width: 8rem;
    }
    @media screen and (max-width: $break-point--480) {
        width: 8rem;
    }
    &.top-tabs__selected {
        color: $color-tab-selected !important;
        background-color: transparent !important;

        > p {
            color: $color-tab-selected !important;
        }
    }
}
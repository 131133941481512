.ranked-games-leaderboard-item {
    border-radius: $container-border--radius;
    overflow: hidden;
    border-top: 2px solid #3d8398;
    background: linear-gradient(#31697a, #275c6b);
    box-shadow: 0 6px 10px 4px #163d46;

    &.current-player {
        border-top-color: #3bd6e0;
        background: linear-gradient(#2eaab4, #1f8089);
    }

    &.sticky {
        border-top-color: #ffff8e;
        background: linear-gradient(#c5ff00, #7cca02);
        color: #252a58;
    }

    &.golden {
        border-top-color: #f7ec6b;
        background: linear-gradient(#eab92d, #c79810);
        color: white;
        font-weight: bold;
        color: #38383a;
    }

    &.silver {
        border-top-color: #feffff;
        background: linear-gradient(#ededed, #cccbcd);
        color: #38383a;
        font-weight: bold;
    }

    &.bronze {
        border-top-color: #eacfc0;
        background: linear-gradient(#cfb6a8, #af8974);
        color: #351604;
        font-weight: bold;
    }

    @media screen and (max-width: $break-point--568) {
        border-top-width: 1px;;
    }
}
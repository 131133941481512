.shop-inventory__container--tile {
    border: none !important;
    @include coloredContainer($container-dark-background-color,
        $container-dark-upper-border-color,
        $container-dark-inner-shadow-color,
        $color__black);
}

.shop-inventory__container--global-items, .shop-inventory__container--game-spec-items {
    margin: 1rem 0;
}